<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="80%"
            :center="true"
            top="2vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="CopTemplate"
                    :rules="rules"
                    ref="CopTemplateEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-form-item label="模板名称" prop="name">
                                <el-input v-model="CopTemplate.name" clearable></el-input>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">发货人信息</legend>
                            <el-form-item label="名称" prop="shipper_name">
                                <el-input v-model="CopTemplate.shipper_name" clearable placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="电话" prop="shipper_phone">
                                        <el-input v-model="CopTemplate.shipper_phone" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="邮箱" prop="shipper_email">
                                        <el-input v-model="CopTemplate.shipper_email" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="国家及地区" prop="shipper_city">
                                        <el-input type="textarea" :rows="3" v-model="CopTemplate.shipper_city" placeholder="必须使用英文填写"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="街道" prop="shipper_street">
                                        <el-input type="textarea" :rows="3" v-model="CopTemplate.shipper_street" placeholder="必须使用英文填写"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="详细地址" prop="shipper_address">
                                <el-input type="textarea" :rows="3" v-model="CopTemplate.shipper_address" placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">收货人信息</legend>
                            <el-form-item label="名称" prop="consignee_name">
                                <el-input v-model="CopTemplate.consignee_name" clearable placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="电话" prop="consignee_phone">
                                        <el-input v-model="CopTemplate.consignee_phone" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="邮箱" prop="consignee_email">
                                        <el-input v-model="CopTemplate.consignee_email" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="国家及地区" prop="consignee_city">
                                        <el-input type="textarea" :rows="3" v-model="CopTemplate.consignee_city" placeholder="必须使用英文填写"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="街道" prop="consignee_street">
                                        <el-input type="textarea" :rows="3" v-model="CopTemplate.consignee_street" placeholder="必须使用英文填写"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="详细地址" prop="consignee_address">
                                <el-input type="textarea" :rows="3" v-model="CopTemplate.consignee_address" placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">通知人信息</legend>
                            <el-form-item label="名称" prop="notify_party_name">
                                <el-input v-model="CopTemplate.notify_party_name" clearable placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="电话" prop="notify_party_phone">
                                        <el-input v-model="CopTemplate.notify_party_phone" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="邮箱" prop="notify_party_email">
                                        <el-input v-model="CopTemplate.notify_party_email" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="国家及地区" prop="notify_party_city">
                                        <el-input type="textarea" :rows="3" v-model="CopTemplate.notify_party_city" placeholder="必须使用英文填写"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="街道" prop="notify_party_street">
                                        <el-input type="textarea" :rows="3" v-model="CopTemplate.notify_party_street" placeholder="必须使用英文填写"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="详细地址" prop="notify_party_address">
                                <el-input type="textarea" :rows="3" v-model="CopTemplate.notify_party_address" placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">联系人信息</legend>
                            <el-form-item label="名称" prop="emergency_contact_name">
                                <el-input v-model="CopTemplate.emergency_contact_name" clearable placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="电话" prop="emergency_contact_phone">
                                        <el-input v-model="CopTemplate.emergency_contact_phone" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="邮箱" prop="emergency_contact_email">
                                        <el-input v-model="CopTemplate.emergency_contact_email" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="详细地址" prop="emergency_contact_address">
                                <el-input type="textarea" :rows="3" v-model="CopTemplate.emergency_contact_address" placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">货物信息</legend>
                            <el-form-item label="货物名称" prop="cargo_name">
                                <el-input v-model="CopTemplate.cargo_name" clearable placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item  label="包装标志" prop="cargo_package_type">
                                        <el-select
                                            v-model="CopTemplate.cargo_package_type"
                                            filterable
                                            clearable
                                            placeholder="选择包装标志"
                                            class="cargo_package_typec"
                                        >
                                            <el-option
                                                v-for="item in AllPackageType"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.type"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="货物数量" prop="cargo_num">
                                        <el-input v-model="CopTemplate.cargo_num" clearable><i slot="suffix">件</i></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="货物重量" prop="cargo_weight">
                                        <el-input v-model="CopTemplate.cargo_weight" clearable><i slot="suffix">KG</i></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="货物体积" prop="cargo_volume">
                                        <el-input v-model="CopTemplate.cargo_volume" clearable><i slot="suffix">m³</i></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="货物说明" prop="cargo_note">
                                <el-input type="textarea" :rows="3" v-model="CopTemplate.cargo_note" placeholder="必须使用英文填写"></el-input>
                            </el-form-item>
                        </fieldset>
                    </div>
                    <div v-else>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">基础信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="1"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>模板名称</span>
                                    </template>
                                    <span>{{CopTemplate.name}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">发货人信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>名称</span>
                                    </template>
                                    <span>{{CopTemplate.shipper_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>电话</span>
                                    </template>
                                    <span>{{CopTemplate.shipper_phone}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>邮箱</span>
                                    </template>
                                    <span>{{CopTemplate.shipper_email}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>国家/地区</span>
                                    </template>
                                    <span>{{CopTemplate.shipper_city}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>街道</span>
                                    </template>
                                    <span>{{CopTemplate.shipper_street}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>详细地址</span>
                                    </template>
                                    <span>{{CopTemplate.shipper_address}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">收货人信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>名称</span>
                                    </template>
                                    <span>{{CopTemplate.consignee_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>电话</span>
                                    </template>
                                    <span>{{CopTemplate.consignee_phone}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>邮箱</span>
                                    </template>
                                    <span>{{CopTemplate.consignee_email}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>国家/地区</span>
                                    </template>
                                    <span>{{CopTemplate.consignee_city}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>街道</span>
                                    </template>
                                    <span>{{CopTemplate.consignee_street}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>详细地址</span>
                                    </template>
                                    <span>{{CopTemplate.consignee_address}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">通知人信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>名称</span>
                                    </template>
                                    <span>{{CopTemplate.notify_party_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>电话</span>
                                    </template>
                                    <span>{{CopTemplate.notify_party_phone}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>邮箱</span>
                                    </template>
                                    <span>{{CopTemplate.notify_party_email}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>国家/地区</span>
                                    </template>
                                    <span>{{CopTemplate.notify_party_city}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>街道</span>
                                    </template>
                                    <span>{{CopTemplate.notify_party_street}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>详细地址</span>
                                    </template>
                                    <span>{{CopTemplate.notify_party_address}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">联系人信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>名称</span>
                                    </template>
                                    <span>{{CopTemplate.emergency_contact_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>电话</span>
                                    </template>
                                    <span>{{CopTemplate.emergency_contact_phone}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>邮箱</span>
                                    </template>
                                    <span>{{CopTemplate.emergency_contact_email}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>详细地址</span>
                                    </template>
                                    <span>{{CopTemplate.emergency_contact_address}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                        <fieldset class="fieldsetc">
                            <legend class="legendc">货物信息</legend>
                            <el-descriptions
                                class="margin-top"
                                :column="2"
                                :labelStyle="{'width':'120px','text-align':'center'}"
                                :contentStyle="{'text-align': 'center'}"
                                border
                            >
                                <el-descriptions-item :span="2">
                                    <template slot="label">
                                        <span>货物名称</span>
                                    </template>
                                    <span>{{CopTemplate.cargo_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>包装标志</span>
                                    </template>
                                    <span>{{CopTemplate.cargo_package_type_name}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>货物数量</span>
                                    </template>
                                    <span>{{CopTemplate.cargo_num+' 件'}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>货物重量</span>
                                    </template>
                                    <span>{{CopTemplate.cargo_weight+' KG'}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <span>货物数量</span>
                                    </template>
                                    <span>{{CopTemplate.cargo_volume+' m³'}}</span>
                                </el-descriptions-item>
                                <el-descriptions-item :span="2" :contentStyle="{'text-align':'left','white-space':'pre-line'}">
                                    <template slot="label">
                                        <span>货物备注</span>
                                    </template>
                                    <span>{{CopTemplate.cargo_note}}</span>
                                </el-descriptions-item>
                            </el-descriptions>
                        </fieldset>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="CopTemplateDel('CopTemplateEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('CopTemplateEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm()"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('CopTemplateEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { cop_template_add_request,cop_template_del_request,cop_template_edit_request } from '@/network/cop/CopTemplate.js'
import { cop_package_type_list_request } from '@/network/list.js'

export default {
    name: 'CopTemplateEditComponent',
    data() {
        return {
            login_loading: false,
            CopTemplate: {
                name: '',
                shipper_name: '',
                shipper_city: '',
                shipper_street: '',
                shipper_address: '',
                shipper_phone: '',
                shipper_email: '',
                consignee_city: '',
                consignee_name: '',
                consignee_street: '',
                consignee_address: '',
                consignee_phone: '',
                consignee_email: '',
                notify_party_name: '',
                notify_party_city: '',
                notify_party_street: '',
                notify_party_address: '',
                notify_party_phone: '',
                notify_party_email: '',
                emergency_contact_name: '',
                emergency_contact_address: '',
                emergency_contact_phone: '',
                emergency_contact_email: '',
                cargo_name: '',
                cargo_package_type: '',
                cargo_num: '',
                cargo_weight: '',
                cargo_volume: '',
                cargo_note: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '模板名称必填',
                        trigger: 'blur',
                    }
                ],
                shipper_name: [
                    {
                        required: true,
                        message: '发货人名称必填',
                        trigger: 'blur',
                    }
                ],
                shipper_city: [
                    {
                        required: true,
                        message: '发货人国家/地区必填',
                        trigger: 'blur',
                    }
                ],
                shipper_street: [
                    {
                        required: true,
                        message: '发货人街道必填',
                        trigger: 'blur',
                    }
                ],
                shipper_phone: [
                    {
                        required: true,
                        message: '发货人电话必填',
                        trigger: 'blur',
                    }
                ],
                consignee_name: [
                    {
                        required: true,
                        message: '收货人名称必填',
                        trigger: 'blur',
                    }
                ],
                consignee_city: [
                    {
                        required: true,
                        message: '收货人国家/地区必填',
                        trigger: 'blur',
                    }
                ],
                consignee_street: [
                    {
                        required: true,
                        message: '收货人街道必填',
                        trigger: 'blur',
                    }
                ],
                consignee_phone: [
                    {
                        required: true,
                        message: '收货人电话必填',
                        trigger: 'blur',
                    }
                ],
                notify_party_name: [
                    {
                        required: true,
                        message: '通知人名称必填',
                        trigger: 'blur',
                    }
                ],
                notify_party_city: [
                    {
                        required: true,
                        message: '通知人国家/地区必填',
                        trigger: 'blur',
                    }
                ],
                notify_party_street: [
                    {
                        required: true,
                        message: '通知人街道必填',
                        trigger: 'blur',
                    }
                ],
                notify_party_phone: [
                    {
                        required: true,
                        message: '通知人电话必填',
                        trigger: 'blur',
                    }
                ],
                emergency_contact_email: [
                    {
                        required: true,
                        message: '订舱人邮箱必填',
                        trigger: 'blur',
                    }
                ],
                cargo_name: [
                    {
                        required: true,
                        message: '货物名称必填',
                        trigger: 'blur',
                    }
                ],
                cargo_package_type: [
                    {
                        required: true,
                        message: '包装标志必填',
                        trigger: 'blur',
                    }
                ],
                cargo_num: [
                    {
                        required: true,
                        message: '货物数量必填',
                        trigger: 'blur',
                    }
                ],
                cargo_weight: [
                    {
                        required: true,
                        message: '货物重量必填',
                        trigger: 'blur',
                    }
                ],
                cargo_volume: [
                    {
                        required: true,
                        message: '货物体积必填',
                        trigger: 'blur',
                    }
                ]
            },
            AllPackageType: []
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '订舱模板详情'
            } else if (this.show_type === 1) {
                return '添加订舱模板'
            } else {
                return '修改订舱模板'
            }
        }
    },
    methods: {
        closeDialog() {
            this.resetForm()
            this.$emit('exitDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.CopTemplateInsert()
            })
        },
        CopTemplateInsert() {
            this.login_loading = true
            cop_template_add_request(this.CopTemplate)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm() {
            this.CopTemplate = {
                name: '',
                shipper_name: '',
                shipper_city: '',
                shipper_street: '',
                shipper_address: '',
                shipper_phone: '',
                shipper_email: '',
                consignee_city: '',
                consignee_name: '',
                consignee_street: '',
                consignee_address: '',
                consignee_phone: '',
                consignee_email: '',
                notify_party_name: '',
                notify_party_city: '',
                notify_party_street: '',
                notify_party_address: '',
                notify_party_phone: '',
                notify_party_email: '',
                emergency_contact_name: '',
                emergency_contact_address: '',
                emergency_contact_phone: '',
                emergency_contact_email: '',
                cargo_name: '',
                cargo_package_type: '',
                cargo_num: '',
                cargo_weight: '',
                cargo_volume: '',
                cargo_note: ''
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.CopTemplateEdit(formName)
            })
        },
        CopTemplateEdit() {
            this.login_loading = true
            this.CopTemplate.id = this.id
            cop_template_edit_request(this.CopTemplate)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.CopTemplate.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        CopTemplateDel() {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    cop_template_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                })
                .catch((err) => {})
        },
        get_all_package_type() {
            cop_package_type_list_request({})
                .then((s) => {
                    this.AllPackageType = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        cop_template_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: String,
        del_auth: String
    },
    created() {
        this.get_all_package_type()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        cop_template_details_data(newVal) {
            this.CopTemplate = newVal
        },
    },
}
</script>

<style lang='less'>
.cargo_package_typec {
    width: 100%;
}
</style>