import request from '../request'

//订舱模板列表
export const cop_template_index_request = p => {
    return request({
        method:'GET',
        url:'cop_template/index',
        params: p
    })
}

//添加订舱模板
export const cop_template_add_request = d => {
    return request({
        method:'POST',
        url:'cop_template/add',
        data: d
    })
}

//删除订舱模板
export const cop_template_del_request = id => {
    return request({
        method:'DELETE',
        url:'cop_template/del',
        data: {
            id: id
        }
    })
}

//修改订舱模板
export const cop_template_edit_request = d => {
    return request({
        method:'PUT',
        url:'cop_template/edit',
        data: d
    })
}

//获取订舱模板详情
export const cop_template_details_request = id => {
    return request({
        method:'GET',
        url:'cop_template/details',
        params: {
            id
        }
    })
}